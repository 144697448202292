import React, {useState, useEffect} from 'react'
import {Grid, Card, Box, Button, Tooltip, Typography} from '@mui/material'
import {useCommonStyles} from '@styles/common.style'
import {useStyles} from '@styles/consultants.style'
import {useTranslation} from 'react-i18next'
import serviceCycles from '@services/serviceCycles'
import serviceCycleJourneys from '@services/serviceCycleJourneys'
import moment from 'moment'
import {Link} from 'react-router-dom'
import getCurrentJourney from '@helpers/currentJourney'
import getNextJourney from '@helpers/nextJourney'
import Loading from '@components/Loading'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import NoImage from '@assets/images/noImage.png'
import LogoOW from '@assets/images/logoOW.png'
import ClearIcon from '@mui/icons-material/Clear'
import Dialog from '@components/Dialog'

const Panel = () => {
  const classes = useCommonStyles()
  const classes2 = useStyles()
  const {t} = useTranslation()
  const [cyclesToday, setCyclesToday] = useState([])
  const [cyclesCompleted, setCyclesCompleted] = useState([])
  const [cyclesActive, setCyclesActive] = useState([])
  const [journeysToday, setJourneysToday] = useState([])
  const [journeysCompleted, setJourneysCompleted] = useState([])
  const [journeysActive, setJourneysActive] = useState([])
  const [loading, setLoading] = useState(true)
  const [openDialog, setOpenDialog] = useState(false)

  useEffect(() => {
    serviceCycles.byConsultant(0).then(response => {
      if (!response.error) {
        addNextJourney(response.data)
      }
    })
    serviceCycleJourneys.getCycleJourneyByConsultant(0).then(response => {
      if (!response.error) {
        addJourneySer(response.data)
      }
    })
  }, [])
  const addJourneySer = data => {
    data.map((journey, i) => {
      const journeyDate = journey.cycleJourneyDateTo
      const day = new Date(journeyDate)
      const now = new Date()

      data[i].completed = Date.parse(journeyDate) < Date.now() ? true : false
      data[i].today = Date.parse(now) == Date.parse(day)
    })
    data.sort(function (a, b) {
      return new Date(a.nextJourneyDateTime) - new Date(b.nextJourneyDateTime)
    })
    var filteredToday = data.filter(data => data.today === true)
    var filteredCompleted = data.filter(data => data.completed === true)
    var filteredActive = data.filter(
      data => data.today === false && data.completed === false
    )
    setJourneysToday(filteredToday)
    setJourneysCompleted(filteredCompleted)
    setJourneysActive(filteredActive)
  }

  const addNextJourney = data => {
    data.map((cycle, i) => {
      const journeyDate = getCurrentJourney(cycle).cycleJourneyDateTo
      const nextJourneyDate = getNextJourney(cycle).cycleJourneyDateTo
      const day = new Date(journeyDate)
      const now = new Date()
      let nextDay = new Date(now)
      nextDay.setHours(23, 59, 59)

      data[i].nextJourneyDateTime = nextJourneyDate
      data[i].completed =
        Date.parse(nextJourneyDate) < Date.now() ? true : false
      data[i].today =
        Date.parse(now) <= Date.parse(day) &&
          Date.parse(day) <= Date.parse(nextDay)
          ? true
          : false
    })
    data.sort(function (a, b) {
      return new Date(a.nextJourneyDateTime) - new Date(b.nextJourneyDateTime)
    })
    var filteredToday = data.filter(data => data.today === true)
    var filteredCompleted = data.filter(data => data.completed === true)
    var filteredActive = data.filter(
      data => data.today === false && data.completed === false
    )
    setCyclesToday(filteredToday)
    setCyclesCompleted(filteredCompleted)
    setCyclesActive(filteredActive)
    setLoading(false)

    if (filteredToday.length > 0 && !expanded.includes('paneltoday')) {
      setExpanded(prevState => [...prevState, 'paneltoday']);
    }

    if (filteredActive.length > 0 && !expanded.includes('panelactivo')) {
      setExpanded(prevState => [...prevState, 'panelactivo']);
    }



  }

  const [expanded, setExpanded] = useState([false])

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(prevState => (
      isExpanded
        ? prevState.includes(panel) ? prevState : [...prevState, panel]
        : prevState.filter(item => item !== panel)
    ));
  };


  const clickOnCancel = cycle => {
    localStorage.setItem('idCycle', JSON.stringify(cycle.idCycle))
    localStorage.setItem('idConsultant', JSON.stringify(cycle.idConsultant))
    setOpenDialog(true)
  }

  const hideJourneys = () => {
    const idCycle = JSON.parse(localStorage.getItem('idCycle'))
    const idConsultant = JSON.parse(localStorage.getItem('idConsultant'))

    serviceCycles
      .hideJourneys({
        idCycle: idCycle,
        idConsultant: idConsultant
      })
      .then(response => {
        if (!response.error) {
          localStorage.removeItem('cycle')
          setCyclesCompleted(cyclesCompleted.filter(x => x.idCycle != idCycle))
          setOpenDialog(false)
        }
      })
  }

  if (loading) {
    return <Loading height='60vh' />
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        close={() => setOpenDialog(false)}
        hasCloseBtn={false}
        title={t('CONFIRM.TITLE_CYCLE')}
        subtitle={t('CONFIRM.UNRECOVERY_DELETE')}
        height={200}
        actions={
          <Box display='flex' alignItems='center'>
            <Box mr={1}>
              <Button
                variant='contained'
                onClick={() => setOpenDialog(false)}
                color='error'
              >
                {t('BUTTON.CANCEL')}
              </Button>
            </Box>
            <Button variant='contained' onClick={() => hideJourneys()}>
              {t('BUTTON.DELETE')}
            </Button>
          </Box>
        }
      />

      {cyclesToday.length > 0 && (
        <Accordion
          expanded={expanded.find(x => x === 'paneltoday') !== undefined || false}
          onChange={handleChange('paneltoday')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'paneltodaybh-content'}
            id={'paneltodaybh-header'}
          >
            <div
              style={{
                color: '#0085B4',
                marginBottom: '4px',
                fontWeight: 'bold', 
                fontSize: '1.2em'
              }}
            >
              {t('PANEL.TODAY')}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {cyclesToday.map((cycle, index) => {
                const nextJourney = getCurrentJourney(cycle)
                console.log(nextJourney)
                const nextJourneyLocation = nextJourney.idCycleJourneyModality == 1
                    ? nextJourney?.cycleJourneyCityShortCode
                        ? nextJourney?.cycleJourneyCityShortCode
                        : nextJourney?.cycleJourneyCityCode
                    : nextJourney?.cycleJourneyDetails
                return (
                  <Link key={index} to={'/journey/' + cycle.idCycle}>
                    <Card
                      variant='outlined'
                      sx={{
                        marginBottom: '10px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Grid container sx={{height: '100%'}}>
                        <Grid
                          item
                          xs={3}
                          padding={0}
                          sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            style={{
                              objectFit: 'contain',
                              maxHeight: '100%',
                              maxWidth: '100%'
                            }}
                            src={
                              cycle.companyLogo != null &&
                                cycle.companyLogo != undefined &&
                                cycle.companyLogo != ''
                                ? `data:image/png;base64,${cycle.companyLogo}`
                                : NoImage
                            }
                            alt='Company logo'
                          />
                        </Grid>
                        <Grid
                          xs={7.5}
                          container
                          item
                          spacing={0}
                          padding={1}
                          sx={{
                            borderRight: '1px dashed grey',
                            borderLeft: '1px dashed grey'
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              color: '#0085B4',
                              fontSize: '20px',
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                            mb={1}
                          >
                            <>
                              {(cycle.cycleNameApp + " - " + cycle.cycleTypeCode)?.length > 18 && (
                                <Tooltip
                                  title={cycle.cycleNameApp + " - " + cycle.cycleTypeCode}
                                  placement='bottom'
                                >
                                  <Typography
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      fontWeight: 'bold', 
                                      fontSize: '1em'
                                    }}
                                  >
                                    {cycle.cycleNameApp  + " - " + cycle.cycleTypeCode}
                                  </Typography>
                                </Tooltip>
                              )}
                              {(cycle.cycleNameApp + " - " + cycle.cycleTypeCode)?.length <= 18 && (
                                <Typography
                                  style={{textOverflow: 'ellipsis', fontWeight : 'bold'}}
                                >
                                  {cycle.cycleNameApp  + " - " + cycle.cycleTypeCode}
                                </Typography>
                              )}
                            </>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <>
                              {nextJourneyLocation != null &&
                                nextJourneyLocation.length > 18 && (
                                  <Tooltip
                                    title={cycle.cycleName}
                                    placement='bottom'
                                  >
                                    <Typography
                                      style={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <strong>
                                        {nextJourneyLocation.toUpperCase()}
                                      </strong>
                                    </Typography>
                                  </Tooltip>
                                )}

                              {nextJourneyLocation == null ||
                                (nextJourneyLocation?.length <= 18 && (
                                  <Typography>
                                    <strong>
                                      {(nextJourneyLocation == null
                                        ? ''
                                        : nextJourneyLocation
                                      ).toUpperCase()}
                                    </strong>
                                  </Typography>
                                ))}
                            </>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            display='flex'
                            justifyContent='flex-end'
                            style={{color: '#0085B4', fontSize: '20px'}}
                          >
                            <strong>
                              {cycle.cycleKeyFirst}-{cycle.cycleKeySecond}
                            </strong>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          display='flex'
                          flexDirection='column'
                          justifyContent='end'
                          alignItems='center'
                          p={1.75}
                          style={{ fontSize: '1.2em' }}
                        >
                          {cycle.consultantCode}
                        </Grid>
                      </Grid>
                    </Card>
                  </Link>
                )
              })}
            </>
          </AccordionDetails>
        </Accordion>
      )}

      {cyclesActive.length > 0 && (
        <Accordion
          expanded={expanded.find(x => x === 'panelactivo') !== undefined || false}
          onChange={handleChange('panelactivo')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'panelactivobh-content'}
            id={'panelactivobh-header'}
          >
            <div
              style={{
                color: '#0085B4',
                marginBottom: '4px',
                fontWeight: 'bold', 
                fontSize: '1.2em'
              }}
            >
              {t('PANEL.ACTIVE')}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {' '}
              {cyclesActive.map((cycle, index) => {
                const todayJourney = getCurrentJourney(cycle)
                const dateTemp = new Date()
                const format = "DD-MM-YY HH:mm"
                let dateJourney = ""
                let nextJourneyLocation = ""
                if (Date.parse(todayJourney.cycleJourneyDateFrom) == dateTemp) {
                  dateJourney = moment(todayJourney.cycleJourneyDateFrom).format(format);
                  nextJourneyLocation = todayJourney.idCycleJourneyModality == 1
                    ? todayJourney?.cycleJourneyCityShortCode
                      ? todayJourney?.cycleJourneyCityShortCode
                      : todayJourney?.cycleJourneyCityCode
                    : todayJourney?.cycleJourneyDetails
                } else {
                  const nextJourney = getNextJourney(cycle)
                  dateJourney = moment(nextJourney.cycleJourneyDateFrom).format(format);
                  nextJourneyLocation = nextJourney.idCycleJourneyModality == 1
                    ? nextJourney?.cycleJourneyCityShortCode
                      ? nextJourney?.cycleJourneyCityShortCode
                      : nextJourney?.cycleJourneyCityCode
                    : nextJourney?.cycleJourneyDetails
                }
                return (
                  <Link key={index} to={'/journey/' + cycle.idCycle}>
                    <Card
                      variant='outlined'
                      sx={{
                        marginBottom: '10px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Grid container sx={{height: '100%'}}>
                        <Grid
                          item
                          xs={3}
                          padding={0}
                          sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            style={{
                              objectFit: 'contain',
                              maxHeight: '100%',
                              maxWidth: '100%'
                            }}
                            src={
                              cycle.companyLogo != null &&
                                cycle.companyLogo != undefined &&
                                cycle.companyLogo != ''
                                ? `data:image/png;base64,${cycle.companyLogo}`
                                : NoImage
                            }
                            alt='Company logo'
                          />
                        </Grid>
                        <Grid
                          xs={7.5}
                          container
                          item
                          spacing={0}
                          padding={0.5}
                          sx={{
                            borderRight: '1px dashed grey',
                            borderLeft: '1px dashed grey'
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              color: '#0085B4',
                              fontSize: '20px',
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                            mb={1}
                          >
                            <>
                              {(cycle.cycleNameApp + " - " + cycle.cycleTypeCode)?.length > 18 && (
                                <Tooltip
                                  title={cycle.cycleNameApp + " - " + cycle.cycleTypeCode}
                                  placement='bottom'
                                >
                                  <Typography
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      fontWeight: 'bold', 
                                      fontSize: '1em'
                                    }}
                                  >
                                    {cycle.cycleNameApp + " - " +  cycle.cycleTypeCode}
                                  </Typography>
                                </Tooltip>
                              )}
                              {(cycle.cycleNameApp  + " - " + cycle.cycleTypeCode)?.length <= 18 && (
                                <Typography
                                  style={{textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '1em'}}
                                >
                                  {cycle.cycleNameApp + " - " + cycle.cycleTypeCode}
                                </Typography>
                              )}
                            </>
                          </Grid>
                          {/* <Grid
                            item
                            xs={4}
                            display='flex'
                            justifyContent='flex-end'
                            // alignItems='center'
                          >
                            <Typography
                              className={classes2.lineHeight}
                              style={{
                                textAlign: 'right',
                                width: '100%',
                                maxWidth: '100%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }}
                              variant='caption'
                            >
                              {dateJourney}
                            </Typography>
                          </Grid> */}
                          <Grid
                            item
                            xs={6}
                            style={{
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <>
                              {nextJourneyLocation != null &&
                                nextJourneyLocation.length > 18 && (
                                  <Tooltip
                                    title={cycle.cycleName}
                                    placement='bottom'
                                  >
                                    <Typography
                                      style={{
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      <strong>
                                        {nextJourneyLocation.toUpperCase()}
                                      </strong>
                                    </Typography>
                                  </Tooltip>
                                )}

                              {nextJourneyLocation == null ||
                                (nextJourneyLocation?.length <= 18 && (
                                  <Typography>
                                    <strong>
                                      {(nextJourneyLocation == null
                                        ? ''
                                        : nextJourneyLocation
                                      ).toUpperCase()}
                                    </strong>
                                  </Typography>
                                ))}
                            </>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            display='flex'
                            justifyContent='flex-end'
                            style={{color: '#0085B4', fontSize: '20px'}}
                          >
                            <strong>
                              {cycle.cycleKeyFirst}-{cycle.cycleKeySecond}
                            </strong>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          display='flex'
                          flexDirection='column'
                          justifyContent='end'
                          alignItems='center'
                          p={1.75}
                          style={{ fontSize: '1.2em' }}
                        >
                            {cycle.consultantCode}
                        </Grid>
                      </Grid>
                    </Card>
                  </Link>
                )
              })}
            </>
          </AccordionDetails>
        </Accordion>
      )}

      {journeysToday.length > 0 && (
        <Accordion
          expanded={expanded.find(x => x === 'paneltodaySer') !== undefined || false}
          onChange={handleChange('paneltodaySer')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'paneltodaybh-content'}
            id={'paneltodaybh-header'}
          >
            <div
              style={{
                color: '#0085B4',
                marginBottom: '4px',
                fontWeight: 'bold', 
                fontSize: '1.2em'
              }}
            >
              {t('PANEL.TODAYSER')}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {journeysToday.map((journey, index) => {
                  const nextJourneyLocation = journey.idCycleJourneyModality == 1
                      ? journey?.cycleJourneyCityShortCode
                          ? journey?.cycleJourneyCityShortCode
                          : journey?.cycleJourneyCityCode
                      : journey?.cycleJourneyDetails
                return (
                  <Link
                    key={index}
                    to={'/journeySer/' + journey.idCycleJourney}
                  >
                    <Card
                      variant='outlined'
                      sx={{
                        marginBottom: '10px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Grid container sx={{height: '100%'}}>
                        <Grid
                          xs={7.5}
                          container
                          item
                          spacing={0}
                          padding={1}
                          sx={{
                            borderRight: '1px dashed grey',
                            borderLeft: '1px dashed grey'
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            style={{
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <>
                              {nextJourneyLocation != null &&
                                nextJourneyLocation.length > 18 && (
                                  <Typography
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <strong>
                                      {nextJourneyLocation.toUpperCase()}
                                    </strong>
                                  </Typography>
                                )}

                              {nextJourneyLocation == null ||
                                (nextJourneyLocation?.length <= 18 && (
                                  <Typography>
                                    <strong>
                                      {(nextJourneyLocation == null
                                        ? ''
                                        : nextJourneyLocation
                                      ).toUpperCase()}
                                    </strong>
                                  </Typography>
                                ))}
                            </>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          display='flex'
                          flexDirection='column'
                          justifyContent='end'
                          alignItems='center'
                          p={1.75}
                          style={{ fontSize: '1.2em' }}
                        >
                          {journey.consultantCode}
                        </Grid>
                      </Grid>
                    </Card>
                  </Link>
                )
              })}
            </>
          </AccordionDetails>
        </Accordion>
      )}
      {journeysActive.length > 0 && (
        <Accordion
          expanded={expanded.find(x => x === 'panelactiveSer') !== undefined || false}
          onChange={handleChange('panelactiveSer')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'paneltodaybh-content'}
            id={'paneltodaybh-header'}
          >
            <div
              style={{
                color: '#0085B4',
                marginBottom: '4px',
                fontWeight: 'bold', 
                fontSize: '1.2em'
              }}
            >
              {t('PANEL.ACTIVESER')}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {journeysActive.map((journey, index) => {
                let nombreSER = " - " + journey.cycleTypeCode + " - " + journey.cycleJourneyIdRecovery + "D";
                  const nextJourneyLocation = journey.idCycleJourneyModality == 1
                      ? journey?.cycleJourneyCityShortCode
                          ? journey?.cycleJourneyCityShortCode
                          : journey?.cycleJourneyCityCode
                      : journey?.cycleJourneyDetails
                return (
                  <Link
                    key={index}
                    to={'/journeySer/' + journey.idCycleJourney}
                  >
                    <Card
                      variant='outlined'
                      sx={{
                        marginBottom: '10px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Grid container sx={{height: '100%'}}>
                        <Grid
                          item
                          xs={3}
                          padding={1.5}
                          sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            style={{
                              objectFit: 'contain',
                              maxHeight: '100%',
                              maxWidth: '100%'
                            }}
                            src={LogoOW}
                            alt='Company logo'
                          />
                        </Grid>
                        <Grid
                          xs={7.5}
                          container
                          item
                          spacing={0}
                          padding={1}
                          sx={{
                            borderRight: '1px dashed grey',
                            borderLeft: '1px dashed grey'
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            style={{
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <>
                              <Typography
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  fontSize: '1.2em'
                                }}
                              >
                                <strong><span style={{color: '#0085B4'}}>SER</span>{nombreSER}</strong>
                              </Typography>
                              {nextJourneyLocation != null &&
                                nextJourneyLocation.length > 18 && (
                                  <Typography
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <strong>
                                      {nextJourneyLocation.toUpperCase()}
                                    </strong>
                                  </Typography>
                                )}

                              {nextJourneyLocation == null ||
                                (nextJourneyLocation?.length <= 18 && (
                                  <Typography>
                                    <strong>
                                      {(nextJourneyLocation == null
                                        ? ''
                                        : nextJourneyLocation
                                      ).toUpperCase()}
                                    </strong>
                                  </Typography>
                                ))}
                            </>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          display='flex'
                          flexDirection='column'
                          justifyContent='end'
                          alignItems='center'
                          p={1.75}
                          style={{ fontSize: '1.2em' }}
                        >
                          {journey.consultantCode}
                        </Grid>
                      </Grid>
                    </Card>
                  </Link>
                )
              })}
            </>
          </AccordionDetails>
        </Accordion>
      )}

      {cyclesCompleted.length > 0 && (
        <Accordion
          expanded={expanded.find(x => x === 'panelcomplete') !== undefined || false}
          onChange={handleChange('panelcomplete')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'panelcompletebh-content'}
            id={'panelcompletebh-header'}
          >
            <div
              style={{
                color: '#0085B4',
                marginBottom: '4px',
                fontWeight: 'bold', 
                fontSize: '1em'
              }}
            >
              {t('PANEL.FINISHED')}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {cyclesCompleted.map((cycle, index) => {
              const nextJourney = getCurrentJourney(cycle)
                const nextJourneyLocation = nextJourney.idCycleJourneyModality == 1
                    ? nextJourney?.cycleJourneyCityShortCode
                        ? nextJourney?.cycleJourneyCityShortCode
                        : nextJourney?.cycleJourneyCityCode
                    : nextJourney?.cycleJourneyDetails
              return (
                <div key={index}>
                  <Link key={index} to={'/journey/' + cycle.idCycle}>
                    <Card
                      variant='outlined'
                      sx={{
                        marginBottom: '10px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Grid container sx={{height: '100%'}}>
                        <Grid
                          item
                          xs={3}
                          padding={0}
                          sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <img
                            style={{
                              objectFit: 'contain',
                              maxHeight: '100%',
                              maxWidth: '100%'
                            }}
                            src={
                              cycle.companyLogo != null &&
                                cycle.companyLogo != undefined &&
                                cycle.companyLogo != ''
                                ? `data:image/png;base64,${cycle.companyLogo}`
                                : NoImage
                            }
                            alt='Company logo'
                          />
                        </Grid>
                        <Grid
                          xs={7.5}
                          container
                          item
                          spacing={0}
                          padding={1}
                          sx={{
                            borderRight: '1px dashed grey',
                            borderLeft: '1px dashed grey'
                          }}
                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              color: 'grey',
                              fontSize: '20px',
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                            mb={1}
                          >
                            <>
                              {(cycle?.cycleNameApp + " - " + cycle?.cycleTypeCode)?.length > 22 && (
                                <Tooltip
                                  title={cycle.cycleNameApp + " - " + cycle.cycleTypeCode}
                                  placement='bottom'
                                >
                                  <Typography
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      fontWeight: 'bold', 
                                      fontSize: '1em'
                                    }}
                                  >
                                    {cycle.cycleNameApp + " - " + cycle.cycleTypeCode}
                                  </Typography>
                                </Tooltip>
                              )}
                              {(cycle?.cycleNameApp + " - " + cycle?.cycleTypeCode)?.length <= 22 && (
                                <Typography
                                  style={{textOverflow: 'ellipsis', fontWeight: 'bold', fontSize: '1em'}}
                                >
                                  {cycle.cycleNameApp + " - " + cycle.cycleTypeCode}
                                </Typography>
                              )}
                            </>
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            style={{
                              color: 'grey',
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <>
                              {nextJourneyLocation != null &&
                                nextJourneyLocation.length > 18 && (
                                  <Tooltip
                                    title={(nextJourneyLocation == null
                                      ? ''
                                      : nextJourneyLocation
                                    ).toUpperCase()}
                                    placement='bottom'
                                  >
                                    <Typography
                                      style={{
                                        maxWidth: '100%',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                      }}
                                    >
                                      {nextJourneyLocation.toUpperCase()}
                                    </Typography>
                                  </Tooltip>
                                )}
                              {nextJourneyLocation == null ||
                                (nextJourneyLocation.length <= 18 && (
                                  <Typography style={{
                                    maxWidth: '100%',
                                    width: '100%',
                                    textOverflow: 'ellipsis',
                                  }}>
                                    {(nextJourneyLocation == null
                                      ? ''
                                      : nextJourneyLocation
                                    ).toUpperCase()}
                                  </Typography>
                                ))}
                            </>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            display='flex'
                            justifyContent='flex-end'
                            style={{color: 'grey', fontSize: '18px'}}
                          >
                            <strong>
                              {cycle.cycleKeyFirst}-{cycle.cycleKeySecond}
                            </strong>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          display='flex'
                          flexDirection='column'
                          justifyContent='end'
                          alignItems='center'
                          p={1.5}
                          style={{ fontSize: '1.2em' }}
                        >
                        {cycle.consultantCode}
                        </Grid>
                      </Grid>
                    </Card>
                  </Link>
                  <button
                    style={{
                      position: 'absolute',
                      right: '1em',
                      transform: 'translateY(-6em)',
                      height: '3.5em',
                      width: '3.5em',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      borderWidth: '0px',
                      cursor: 'pointer'
                    }}
                    onClick={() => clickOnCancel(cycle)}
                  >
                    <ClearIcon
                      sx={{
                        color: 'red',
                        fontSize: '1.5em',
                        fontWeight: '600'
                      }}
                    />
                  </button>
                </div>
              )
            })}
          </AccordionDetails>
        </Accordion>
      )}


      {/*                  No quieren ahora que salgan las Jornadas SER finalizadas


      {journeysCompleted.length > 0 && (
        <Accordion
          expanded={expanded.find( x => x ==='panelcompleteSer' ) !== undefined || false}
          onChange={handleChange('panelcompleteSer')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={'paneltodaybh-content'}
            id={'paneltodaybh-header'}
          >
            <div
              style={{
                color: '#0085B4',
                marginBottom: '4px',
                fontWeight: 'bold',
                fontSize: '1em'
              }}
            >
              {t('PANEL.FINISHEDSER')}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <>
              {journeysCompleted.map((journey, index) => {
                let fecha = new Date(
                  journey.cycleJourneyDateFrom
                  ).toLocaleString()
                let nombreSER = "SER - " + journey.cycleTypeCode + " - " + journey.cycleJourneyIdRecovery + "D";
                const nextJourneyLocation =
                  journey.idCycleJourneyModality == 1
                    ? journey?.cycleJourneyCityCode
                    : journey?.cycleJourneyDetails
                return (
                  <Link
                    key={index}
                    to={'/journeySer/' + journey.idCycleJourney}
                  >
                    <Card
                      variant='outlined'
                      sx={{
                        marginBottom: '10px',
                        height: '70px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <Grid container sx={{ height: '100%' }}>
                        <Grid
                          xs={7.5}
                          container
                          item
                          spacing={0}
                          padding={1}
                          sx={{
                            borderRight: '1px dashed grey',
                            borderLeft: '1px dashed grey'
                          }}
                        >
                          <Grid
                            item
                            xs={6}
                            style={{
                              height: '1.2em',
                              whiteSpace: 'nowrap'
                            }}
                          >
                            <>
                              <Typography
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden'
                                }}
                              >
                                <strong>{nombreSER}</strong>
                              </Typography>
                              {nextJourneyLocation != null &&
                                nextJourneyLocation.length > 18 && (
                                  <Typography
                                    style={{
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden'
                                    }}
                                  >
                                    <strong>
                                      {nextJourneyLocation.toUpperCase()}
                                    </strong>
                                  </Typography>
                                )}

                              {nextJourneyLocation == null ||
                                (nextJourneyLocation?.length <= 18 && (
                                  <Typography>
                                    <strong>
                                      {(nextJourneyLocation == null
                                        ? ''
                                        : nextJourneyLocation
                                      ).toUpperCase()}
                                    </strong>
                                  </Typography>
                                ))}
                            </>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          display='flex'
                          flexDirection='column'
                          justifyContent='end'
                          alignItems='center'
                          p={1.75}
                        >
                          {journey.consultantCode}
                        </Grid>
                      </Grid>
                    </Card>
                  </Link>
                )
              })}
            </>
          </AccordionDetails>
        </Accordion>
      )}

      */}

    </div>
  )
}

export default Panel
